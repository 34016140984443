<template>
	<v-container>
		<v-text-field
			v-model="item.old_password"
			label="기존 비밀번호"
			placeholder="기존 비밀번호를 입력하세요"
			outlined
			color="success2"
			hide-detail
			
			class="mt-10"
			:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
			:type="show1 ? 'text' : 'password'"
			@click:append="show1 = !show1"
		></v-text-field>
		
		
		<v-text-field
			v-model="item.password"
			label="신규 비밀번호"
			placeholder="새로운 비밀번호를 입력하세요"
			outlined
			color="success2"
			hide-detail
			
			:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
			:type="show2 ? 'text' : 'password'"
			@click:append="show2 = !show2"
		></v-text-field>
		
		
		<v-text-field
			v-model="item.password_confirm"
			label="비밀번호 확인"
			outlined
			placeholder="신규 비밀번호를 한번 더 입력하세요"
			color="success2"
			hide-detail
			
			:append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
			:type="show3 ? 'text' : 'password'"
			@click:append="show3 = !show3"
		></v-text-field>
		
		<v-btn
			v-if="false"
			block
			@click="setPassword"
			color="success2"
			:disabled="is_set ? false : true"
		>
			비밀번호 변경
		</v-btn>
	</v-container>
</template>
<script>

	export default{
		name: 'FindId'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
		}
		,props: ['callBack']
		,data: function(){
			return{
				program: {
					title: '비밀번호 변경'
					,next: {
						title: '완료'
						,do: false
						,callBack: 'setPassword'
					}
					,not_navigation: true
				}
				,item: {
					password: ''
					,password_confirm: ''
					,old_password: ''
				}
				,is_set: false
				,show1: false
				,show2: false
				,show3: false
			}
		}
		,methods: {
			go: function(type){
				if(type == 'id'){
					this.$router.push('/Help/FindId')
				}else{
					this.$router.push('/Help/FindPw')
				}
			}
			,find: function(){
				this.$emit('axios',{
					request_type: 'get'
					, request_url: ''
					, request_params: {
						email: this.email
						,phone: this.phone
					}
					,callBack: 'result'
					,dev: 'success'
				})
			}
			,result: function(call){
				
				if(call.result){
					this.$emit('modal', {
						title: '비밀번호 변경'
						,contents: '비밀번호가 변경되었습니다'
						,contents_type: 'text'
						,accept: '확인'
						,icon: 'mdi-check-circle'
						,icon_color: 'success'
					})
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,setPassword: function(){
				if(this.item.password != this.item.password_confirm){
					this.$emit('alertMsg', 'error', '비밀번호를 다시 확인해주세요')
					return false
				}
				this.$emit('axios', {
					request_type: 'put'
					,request_url: '/users/me/password'
					,request_params: {
						newPassword: this.item.password
						,password: this.item.old_password
					}
					,authorize: true
					,callBack: 'result'
				})
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'result'){
						this.result(call)
					}else if(call.name == 'setPassword'){
						this.setPassword()
					}
				}
			}
			,item: {
				deep: true
				,handler: function(call){
					console.log(call)
					for(let[key, val] of Object.entries(call)){
						console.log(key)
						if(!val || val == '' || val == undefined){
							this.is_set = false
							return false
						}
					}
					this.is_set = true
				}
			}
			,is_set: {
				handler: function(call){
					if(call){
						this.program.next.do = true
					}else{
						this.program.next.do = false
					}
				}
			}
		}
	}
</script>
<style>
	a {color: black !important; padding: 0;}
</style>